import { Source, Layer } from "react-map-gl";
import { getAirportBoundaries } from "../../../requests/getData";

import { useQuery } from "@tanstack/react-query";

function AirportBoundaryLayer({ mapOption }) {
  const { isError, data, error } = useQuery({
    queryKey: ["airportBoundaries"],
    queryFn: getAirportBoundaries,
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    placeholderData: {
      type: "FeatureCollection",
      features: [],
    },
  });

  if (isError) {
    console.error(error);
    return <></>;
  }

  return (
    <Source id="airport-boundaries" type="geojson" data={data}>
      <Layer
        id="airport-boundaries-layer"
        type="line"
        paint={{
          "line-color": mapOption === "Light" ? "#717171" : "#ffffff",
          "line-width": 1.5,
        }}
      ></Layer>
    </Source>
  );
}

export default AirportBoundaryLayer;
