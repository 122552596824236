import { Source, Layer } from "react-map-gl";
import getData from "../../../requests/getData";
import { altitudeSegments } from "../../../requests/register";
import { useQueries } from "@tanstack/react-query";
import { InfinitySpin } from "react-loader-spinner";

function FlightTrackAltitudeLayer({ flightTracksSegmentIds }) {
  // Get all the necessary flight data
  const segmentQueries = useQueries({
    queries: flightTracksSegmentIds.flatMap((key) => {
      return {
        queryKey: ["altitudeSegments", key],
        queryFn: async () => {
          const data = await getData(altitudeSegments[`subtrack_id_${key}`]);
          return data;
        },
        cacheTime: Infinity,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
      };
    }),
  });

  if (segmentQueries.some((obj) => obj.isLoading === true)) {
    return (
      <>
        <InfinitySpin width="150" color="#081E3F" />
      </>
    );
  }

  return (
    <>
      {segmentQueries.map((segmentQuery, index) => {
        return (
          <Source
            key={index}
            id={`flight-path-altitudes-${index}`}
            type="geojson"
            lineMetrics={true}
            data={segmentQuery.data}
          >
            <Layer
              id={`flight-path-altitudes-${index}-layer`}
              type="symbol"
              layout={{
                "text-field": [
                  "concat",
                  ["get", "value"],
                  " ",
                  ["get", "units"],
                ],
                "text-allow-overlap": true,
                "text-anchor": "bottom",
              }}
              paint={{
                "text-halo-color": "rgba(255, 255, 255, 100)",
                "text-halo-width": 3,
              }}
              filter={["!=", ["get", "value"], "0"]}
            />
          </Source>
        );
      })}
    </>
  );
}

export default FlightTrackAltitudeLayer;
