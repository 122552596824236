import { useQuery } from "@tanstack/react-query";
import { Layer, Source } from "react-map-gl";
import getData from "../../../requests/getData";

function ChangeAirportBoundaryLayer({
  layerId,
  beforeId,
  airportBoundaryUrl,
  mapOption,
}) {
  const { isError, data, error } = useQuery({
    queryKey: ["airportBoundaries", airportBoundaryUrl],
    queryFn: async () => {
      const data = await getData(airportBoundaryUrl);
      return data;
    },
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    placeholderData: {
      type: "FeatureCollection",
      features: [],
    },
  });

  if (isError) {
    console.error(error);
    return <></>;
  }

  // Set the source id
  const sourceId = layerId + "-data";

  return (
    <Source id={sourceId} type="geojson" data={data}>
      <Layer
        source={sourceId}
        id={layerId}
        beforeId={beforeId}
        type="line"
        paint={{
          "line-color": mapOption === "Light" ? "#717171" : "#ffffff",
          "line-width": 1.5,
        }}
      />
    </Source>
  );
}

export default ChangeAirportBoundaryLayer;
