import React, { useEffect, useState } from "react";
import i18next from "../../i18n";
import { Trans } from "react-i18next";
// styling imports
import forwardIcon from "../../images/icons/forward-arrow-icon.png";
import slide1 from "../../images/slides/Intro Screen - 1.png";
import slideMobile1 from "../../images/slides/Intro screen_Mobile_1.png";
import slideMobile2 from "../../images/slides/Intro screen_Mobile_2.png";
import slideMobile3 from "../../images/slides/Intro screen_Mobile_3.png";
import slideMobile4 from "../../images/slides/Intro screen_Mobile_4.png";
import slide2 from "../../images/slides/Intro Screen - 2.png";
import slide3 from "../../images/slides/Intro Screen - 3.png";
import slide4 from "../../images/slides/Intro Screen - 4.png";
import "../../styles/introduction/Slide.css";

function Slide({
  setSlideSelected,
  setShowIntro,
  caption,
  captionMobile,
  previousSlide,
  nextSlide,
  slideSelected,
}) {
  const hasPrevious = previousSlide !== undefined;
  const hasNext = nextSlide !== undefined;

  const MOBILE_MAX_WIDTH = 560;
  const [isMobile, setIsMobile] = useState(false);

  const handleSetIsMobile = () => {
    setIsMobile(window.innerWidth < MOBILE_MAX_WIDTH);
  };

  useEffect(() => {
    handleSetIsMobile();
    window.addEventListener("resize", handleSetIsMobile);

    return () => {
      window.removeEventListener("resize", handleSetIsMobile);
    };
  }, []);

  function visitPreviousSlide() {
    if (hasPrevious) {
      setSlideSelected(previousSlide);
    }
  }
  function visitNextSlide() {
    if (hasNext) {
      setSlideSelected(nextSlide);
    }
  }

  const renderIcon = (slide) => {
    switch (slide) {
      case "slide1":
        return (
          <img
            src={isMobile ? slideMobile1 : slide1}
            alt="a plane icon with an curved line next to it."
            className="modal-picture"
          />
        );
      case "slide2":
        return (
          <img
            src={isMobile ? slideMobile2 : slide2}
            alt="a rectangular warning sign."
          />
        );
      case "slide3":
        return (
          <img
            src={isMobile ? slideMobile3 : slide3}
            alt="a line with multiple points leading to an end location/point."
          />
        );
      case "slide4":
        return (
          <img
            src={isMobile ? slideMobile4 : slide4}
            alt="a line with multiple points leading to an end location/point."
          />
        );
      default:
        return null;
    }
  };

  const PreviousSlideButton = () => {
    return (
      <div
        className={
          "modal-arrow-wrapper " + (hasPrevious && "modal-arrow-active")
        }
        onClick={visitPreviousSlide}
      >
        {hasPrevious && (
          <img
            src={forwardIcon}
            alt="an arrow icon that points to the left"
            className="modal-left-arrow"
          />
        )}
      </div>
    );
  };

  const NextSlideButton = () => {
    return (
      <div
        className={"modal-arrow-wrapper " + (hasNext && "modal-arrow-active")}
        onClick={visitNextSlide}
      >
        {nextSlide && (
          <img
            src={forwardIcon}
            alt="an arrow icon that points to the right"
            className="modal-right-arrow"
          />
        )}
      </div>
    );
  };

  return (
    <div className="modal-wrapper">
      {isMobile ? (
        <div className="modal-intro-screenshot-small-screen">
          {renderIcon(slideSelected)}
        </div>
      ) : null}
      <div className="slide-modal">
        <PreviousSlideButton />
        <section className="modal-content">
          {isMobile ? null : (
            <div className="modal-intro-screenshot-large-screen">
              {renderIcon(slideSelected)}
            </div>
          )}
          <div className="modal-bottom">
            <div className="modal-intro-text">
              {
                <Trans
                  i18nKey={isMobile ? captionMobile : caption}
                  // eslint-disable-next-line
                  components={{ italic: <i />, bold: <strong /> }}
                />
              }
            </div>
            <div className="modal-intro-footer">
              {nextSlide ? (
                <a
                  href="#skipping-intro"
                  onClick={() => {
                    setShowIntro(false);
                  }}
                >
                  {i18next.t("intro.skip")}
                </a>
              ) : (
                <button
                  className="modal-end-button"
                  onClick={() => {
                    setShowIntro(false);
                  }}
                >
                  {i18next.t("intro.start")}
                </button>
              )}
            </div>
          </div>
        </section>
        <NextSlideButton />
      </div>
    </div>
  );
}

export default Slide;
