import { Source, Layer } from "react-map-gl";
import { non_jet_path_color } from "../../../colors";
import { filterNonJetPaths } from "./utils";

function PathLineLayer({
  query,
  selectedArrivalNumbers,
  selectedDepartureNumbers,
}) {
  // Check if there are any selected tracks
  // let noSelection =
  //   selectedArrivalNumbers.length === 0 &&
  //   selectedDepartureNumbers.length === 0;

  // Get the arrival data
  const { isError, data } = query;

  if (isError) {
    return <></>;
  }

  const filteredData = filterNonJetPaths(data);

  return (
    <Source id="arrival-track-line" type="geojson" data={filteredData}>
      <Layer
        source="arrival-track-line"
        type="line"
        paint={{
          "line-color": non_jet_path_color,
          "line-width": 1,
          "line-opacity": 1,
          "line-dasharray": [5, 5],
        }}
      />
    </Source>
  );
}

export default PathLineLayer;
