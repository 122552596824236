import React from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { arrival_track_color, departure_track_color } from "../../../colors";
import i18next from "../../../i18n";
import diagonalArrivals from "../../../images/Map/diagonal-stripes-arrivals.png";
import diagonalFlightPaths from "../../../images/Map/diagonal-stripes-flight-paths.png";
import diagonalDepartures from "../../../images/Map/diagonal-stripes-departures.png";
import "../../../styles/map/Legend.css";
import LegendItem, { legendItemStyle } from "./LegendItem";

function FlightLegend({ legendDescription, setLegendDescription }) {
  return (
    <>
      <div className="d-flex align-items-center justify-content-between w-100">
        <dt id="legend-title">{i18next.t("legend.flightpaths.title")}</dt>
        <AiOutlineInfoCircle
          color={"white"}
          className="legend-info-icon"
          onClick={() => {
            setLegendDescription(!legendDescription);
          }}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setLegendDescription(!legendDescription);
            }
          }}
        />
      </div>
      <LegendItem
        name={i18next.t("legend.flightpaths.arrivals")}
        color={arrival_track_color}
      />
      {legendDescription && (
        <div className="legend-info-text">
          {i18next.t("legend.flightpaths.arrivals.info")}
        </div>
      )}
      <LegendItem
        name={i18next.t("legend.flightpaths.departures")}
        color={departure_track_color}
      />
      {legendDescription && (
        <div className="legend-info-text">
          {i18next.t("legend.flightpaths.departures.info")}
        </div>
      )}
      <dt className="colour-identifiers">
        <img
          src={diagonalFlightPaths}
          alt="the pattern for Non-Jet flight paths"
          style={legendItemStyle}
        />
        {i18next.t("legend.flightpaths.nonJet")}
      </dt>
      {legendDescription && (
        <div className="legend-info-text">
          {i18next.t("legend.flightpaths.nonJet.info")}
        </div>
      )}
      <dt className="colour-identifiers">
        <img
          style={legendItemStyle}
          src={diagonalArrivals}
          alt="the pattern for vectoring areas of arrivals"
        />
        {i18next.t("legend.flightpaths.arrivalVectoringAreas")}
      </dt>
      {legendDescription && (
        <div className="legend-info-text">
          {i18next.t("legend.flightpaths.arrivalVectoringAreas.info")}
        </div>
      )}
      <dt className="colour-identifiers">
        <img
          src={diagonalDepartures}
          alt="the pattern for vectoring areas of departures"
          style={legendItemStyle}
        />
        {i18next.t("legend.flightpaths.departureVectoringAreas")}
      </dt>
      {legendDescription && (
        <div className="legend-info-text">
          {i18next.t("legend.flightpaths.departureVectoringAreas.info")}
        </div>
      )}
    </>
  );
}

export default FlightLegend;
