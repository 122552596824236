import React from "react";
import Slide from "./Slide";
import ToolHelpBox from "./ToolHelpBox";
// stying imports
import "../../styles/introduction/IntroductionSlideHolder.css";
import i18next from "../../i18n";
import AboutSlide from "./AboutSlide";

function IntroductionSlideHolder({
  setShowIntro,
  slideSelected,
  setSlideSelected,
  showHelpArrow,
  setShowHelpArrow,
}) {
  let choosenSlide;
  if (slideSelected === "tool-help-box") {
    choosenSlide = (
      <ToolHelpBox
        setSlideSelected={setSlideSelected}
        slideSelected={slideSelected}
        setShowIntro={setShowIntro}
        showHelpArrow={showHelpArrow}
        setShowHelpArrow={setShowHelpArrow}
      />
    );
  } else if (slideSelected === "slide1") {
    choosenSlide = (
      <Slide
        setSlideSelected={setSlideSelected}
        slideSelected={slideSelected}
        setShowIntro={setShowIntro}
        caption={i18next.t("intro.slides.1.text")}
        captionMobile={i18next.t("intro.slides.mobile.1.text")}
        nextSlide="slide2"
      />
    );
  } else if (slideSelected === "slide2") {
    choosenSlide = (
      <Slide
        setSlideSelected={setSlideSelected}
        slideSelected={slideSelected}
        setShowIntro={setShowIntro}
        caption={i18next.t("intro.slides.2.text")}
        captionMobile={i18next.t("intro.slides.mobile.2.text")}
        previousSlide="slide1"
        nextSlide="slide3"
      />
    );
  } else if (slideSelected === "slide3") {
    choosenSlide = (
      <Slide
        setSlideSelected={setSlideSelected}
        slideSelected={slideSelected}
        setShowIntro={setShowIntro}
        caption={i18next.t("intro.slides.3.text")}
        captionMobile={i18next.t("intro.slides.mobile.3.text")}
        previousSlide="slide2"
        nextSlide="slide4"
      />
    );
  } else if (slideSelected === "slide4") {
    choosenSlide = (
      <Slide
        setSlideSelected={setSlideSelected}
        slideSelected={slideSelected}
        setShowIntro={setShowIntro}
        caption={i18next.t("intro.slides.4.text")}
        captionMobile={i18next.t("intro.slides.mobile.4.text")}
        previousSlide="slide3"
      />
    );
  } else if (slideSelected === "about") {
    choosenSlide = <AboutSlide setShowIntro={setShowIntro} />;
  }

  return <div className="modal-background">{choosenSlide}</div>;
}

export default IntroductionSlideHolder;
