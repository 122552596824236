// Set the CDN endpoint
const cdn = "https://cdn.wsiflightpaths.aerlabs.com";

// runway
const runway_url = cdn + "/runways/wsa_runway.geojson";
const runway_label_url = cdn + "/runways/runway_labels.geojson";

// airport boundaries
const airport_boundaries_url =
  cdn + "/airport_boundaries/airport_boundaries.geojson";

// tracks
const runway05_day_arrival_url = cdn + "/swathes/arrivals_day_05.geojson";
const runway05_day_departure_url = cdn + "/swathes/departures_day_05.geojson";
const runway05_night_arrival_url =
  cdn + "/swathes/arrivals_night_05_v2.geojson";
const runway05_night_departure_url =
  cdn + "/swathes/departures_night_05.geojson";
const runway23_day_arrival_url = cdn + "/swathes/arrivals_day_23.geojson";
const runway23_day_departure_url =
  cdn + "/swathes/departures_day_23_v2.geojson";
const runway23_night_arrival_url = cdn + "/swathes/arrivals_night_23.geojson";
const runway23_night_departure_url =
  cdn + "/swathes/departures_night_23.geojson";
const rro_night_arrival_url = cdn + "/swathes/arrivals_night_rro_v2.geojson";
const rro_night_departure_url =
  cdn + "/swathes/departures_night_rro_v2.geojson";
const naprro_night_arrival_url =
  cdn + "/swathes/arrivals_nightnap_rro_v2.geojson";
const naprro_night_departure_url =
  cdn + "/swathes/departures_nightnap_rro_v2.geojson";

// lines
const runway05_day_arrival_line_url = cdn + "/lines/arrivals_day_05.geojson";
const runway05_day_departure_line_url =
  cdn + "/lines/departures_day_05.geojson";
const runway05_night_arrival_line_url =
  cdn + "/lines/arrivals_night_05_v2.geojson";
const runway05_night_departure_line_url =
  cdn + "/lines/departures_night_05.geojson";
const runway23_day_arrival_line_url = cdn + "/lines/arrivals_day_23.geojson";
const runway23_day_departure_line_url =
  cdn + "/lines/departures_day_23_v2.geojson";
const runway23_night_arrival_line_url =
  cdn + "/lines/arrivals_night_23.geojson";
const runway23_night_departure_line_url =
  cdn + "/lines/departures_night_23.geojson";
const rro_night_arrival_line_url = cdn + "/lines/arrivals_night_rro_v2.geojson";
const rro_night_departure_line_url =
  cdn + "/lines/departures_night_rro_v2.geojson";
const naprro_night_arrival_line_url =
  cdn + "/lines/arrivals_nightnap_rro_v2.geojson";
const naprro_night_departure_line_url =
  cdn + "/lines/departures_nightnap_rro_v2.geojson";

// vectoring areas
const vectoring_05_departure_day_url =
  cdn + "/vectoring_areas/departures_day_05.geojson";
const vectoring_23_arrival_night_url =
  cdn + "/vectoring_areas/arrivals_night_23.geojson";
const vectoring_23_departure_day_url =
  cdn + "/vectoring_areas/departures_day_23.geojson";
const vectoring_rro_departure_nightnap_url =
  cdn + "/vectoring_areas/departures_nightnap_rro_v2.geojson";

// Track altitudes
const subtrack_id_100 = cdn + "/altitude_segments/subtrack_id_100.geojson";
const subtrack_id_109 = cdn + "/altitude_segments/subtrack_id_109.geojson";
const subtrack_id_110 = cdn + "/altitude_segments/subtrack_id_110.geojson";
const subtrack_id_120 = cdn + "/altitude_segments/subtrack_id_120.geojson";
const subtrack_id_135 = cdn + "/altitude_segments/subtrack_id_135.geojson";
const subtrack_id_144 = cdn + "/altitude_segments/subtrack_id_144_v2.geojson";
const subtrack_id_151 = cdn + "/altitude_segments/subtrack_id_151.geojson";
const subtrack_id_173 = cdn + "/altitude_segments/subtrack_id_173.geojson";
const subtrack_id_18 = cdn + "/altitude_segments/subtrack_id_18.geojson";
const subtrack_id_194 = cdn + "/altitude_segments/subtrack_id_194.geojson";
const subtrack_id_198 = cdn + "/altitude_segments/subtrack_id_198.geojson";
const subtrack_id_20 = cdn + "/altitude_segments/subtrack_id_20_v2.geojson";
const subtrack_id_207 = cdn + "/altitude_segments/subtrack_id_207.geojson";
const subtrack_id_220 = cdn + "/altitude_segments/subtrack_id_220.geojson";
const subtrack_id_230 = cdn + "/altitude_segments/subtrack_id_230.geojson";
const subtrack_id_246 = cdn + "/altitude_segments/subtrack_id_246.geojson";
const subtrack_id_257 = cdn + "/altitude_segments/subtrack_id_257.geojson";
const subtrack_id_270 = cdn + "/altitude_segments/subtrack_id_270.geojson";
const subtrack_id_282 = cdn + "/altitude_segments/subtrack_id_282_v2.geojson";
const subtrack_id_285 = cdn + "/altitude_segments/subtrack_id_285.geojson";
const subtrack_id_295 = cdn + "/altitude_segments/subtrack_id_295.geojson";
const subtrack_id_305 = cdn + "/altitude_segments/subtrack_id_305.geojson";
const subtrack_id_310 = cdn + "/altitude_segments/subtrack_id_310.geojson";
const subtrack_id_315 = cdn + "/altitude_segments/subtrack_id_315.geojson";
const subtrack_id_328 = cdn + "/altitude_segments/subtrack_id_328.geojson";
const subtrack_id_339 = cdn + "/altitude_segments/subtrack_id_339.geojson";
const subtrack_id_354 = cdn + "/altitude_segments/subtrack_id_354.geojson";
const subtrack_id_359 = cdn + "/altitude_segments/subtrack_id_359.geojson";
const subtrack_id_36 = cdn + "/altitude_segments/subtrack_id_36.geojson";
const subtrack_id_364 = cdn + "/altitude_segments/subtrack_id_364_v2.geojson";
const subtrack_id_37 = cdn + "/altitude_segments/subtrack_id_37.geojson";
const subtrack_id_400 = cdn + "/altitude_segments/subtrack_id_400.geojson";
const subtrack_id_417 = cdn + "/altitude_segments/subtrack_id_417.geojson";
const subtrack_id_455 = cdn + "/altitude_segments/subtrack_id_455.geojson";
const subtrack_id_46 = cdn + "/altitude_segments/subtrack_id_46.geojson";
const subtrack_id_471 = cdn + "/altitude_segments/subtrack_id_471.geojson";
const subtrack_id_482 = cdn + "/altitude_segments/subtrack_id_482.geojson";
const subtrack_id_5 = cdn + "/altitude_segments/subtrack_id_5.geojson";
const subtrack_id_502 = cdn + "/altitude_segments/subtrack_id_502.geojson";
const subtrack_id_517 = cdn + "/altitude_segments/subtrack_id_517.geojson";
const subtrack_id_573 = cdn + "/altitude_segments/subtrack_id_573.geojson";
const subtrack_id_615 = cdn + "/altitude_segments/subtrack_id_615.geojson";
const subtrack_id_625 = cdn + "/altitude_segments/subtrack_id_625.geojson";
const subtrack_id_6251 = cdn + "/altitude_segments/subtrack_id_6251.geojson";
const subtrack_id_6258 = cdn + "/altitude_segments/subtrack_id_6258.geojson";
const subtrack_id_6270 = cdn + "/altitude_segments/subtrack_id_6270.geojson";
const subtrack_id_6281 = cdn + "/altitude_segments/subtrack_id_6281.geojson";
const subtrack_id_85 = cdn + "/altitude_segments/subtrack_id_85.geojson";
const subtrack_id_92 = cdn + "/altitude_segments/subtrack_id_92.geojson";
const subtrack_id_50000 = cdn + "/altitude_segments/subtrack_id_50000.geojson";
const subtrack_id_50001 =
  cdn + "/altitude_segments/subtrack_id_50001_v2.geojson";
const subtrack_id_50002 =
  cdn + "/altitude_segments/subtrack_id_50002_v2.geojson";
const subtrack_id_50003 =
  cdn + "/altitude_segments/subtrack_id_50003_v2.geojson";
const subtrack_id_50004 =
  cdn + "/altitude_segments/subtrack_id_50004_v2.geojson";
const subtrack_id_50005 =
  cdn + "/altitude_segments/subtrack_id_50005_v2.geojson";
const subtrack_id_50006 =
  cdn + "/altitude_segments/subtrack_id_50006_v2.geojson";
const subtrack_id_50007 =
  cdn + "/altitude_segments/subtrack_id_50007_v2.geojson";
const subtrack_id_50008 =
  cdn + "/altitude_segments/subtrack_id_50008_v2.geojson";
const subtrack_id_50009 =
  cdn + "/altitude_segments/subtrack_id_50009_v2.geojson";

// noise
// s1 + 2033
const anec_s1_2033_url = cdn + "/noise-contours/2033_ANEC_S1.geojson";
const n70_s1_2033_url = cdn + "/noise-contours/2033_N70_S1.geojson";
const n60n_s1_2033_url = cdn + "/noise-contours/2033_N60N_S1.geojson";
const n60_s1_2033_url = cdn + "/noise-contours/2033_N60_24h_S1.geojson";
// s1 + 2040
const anec_s1_2040_url = cdn + "/noise-contours/2040_ANEC_S1.geojson";
const n70_s1_2040_url = cdn + "/noise-contours/2040_N70_S1.geojson";
const n60n_s1_2040_url = cdn + "/noise-contours/2040_N60N_S1.geojson";
const n60_s1_2040_url = cdn + "/noise-contours/2040_N60_24h_S1.geojson";
// s1 + 2055
const anec_s1_2055_url = cdn + "/noise-contours/2055_ANEC_S1.geojson";
const n70_s1_2055_url = cdn + "/noise-contours/2055_N70_S1.geojson";
const n60n_s1_2055_url = cdn + "/noise-contours/2055_N60N_S1.geojson";
const n60_s1_2055_url = cdn + "/noise-contours/2055_N60_24h_S1.geojson";
// s3 + 2033
const anec_s3_2033_url = cdn + "/noise-contours/2033_ANEC_S3.geojson";
const n70_s3_2033_url = cdn + "/noise-contours/2033_N70_S3.geojson";
const n60n_s3_2033_url = cdn + "/noise-contours/2033_N60N_S3_v2.geojson";
const n60_s3_2033_url = cdn + "/noise-contours/2033_N60_24h_S3.geojson";
// s3 + 2040
const anec_s3_2040_url = cdn + "/noise-contours/2040_ANEC_S3.geojson";
const n70_s3_2040_url = cdn + "/noise-contours/2040_N70_S3.geojson";
const n60n_s3_2040_url = cdn + "/noise-contours/2040_N60N_S3_v2.geojson";
const n60_s3_2040_url = cdn + "/noise-contours/2040_N60_24h_S3.geojson";
// s3 + 2055
const anec_s3_2055_url = cdn + "/noise-contours/2055_ANEC_S3.geojson";
const n70_s3_2055_url = cdn + "/noise-contours/2055_N70_S3.geojson";
const n60n_s3_2055_url = cdn + "/noise-contours/2055_N60N_S3_v2.geojson";
const n60_s3_2055_url = cdn + "/noise-contours/2055_N60_24h_S3.geojson";
// s4 + 2033
const anec_s4_2033_url = cdn + "/noise-contours/2033_ANEC_S4.geojson";
const n70_s4_2033_url = cdn + "/noise-contours/2033_N70_S4.geojson";
const n60n_s4_2033_url = cdn + "/noise-contours/2033_N60N_S4_v2.geojson";
const n60_s4_2033_url = cdn + "/noise-contours/2033_N60_24h_S4.geojson";
// s4 + 2040
const anec_s4_2040_url = cdn + "/noise-contours/2040_ANEC_S4.geojson";
const n70_s4_2040_url = cdn + "/noise-contours/2040_N70_S4.geojson";
const n60n_s4_2040_url = cdn + "/noise-contours/2040_N60N_S4_v2.geojson";
const n60_s4_2040_url = cdn + "/noise-contours/2040_N60_24h_S4.geojson";
// s4 + 2055
const anec_s4_2055_url = cdn + "/noise-contours/2055_ANEC_S4.geojson";
const n70_s4_2055_url = cdn + "/noise-contours/2055_N70_S4.geojson";
const n60n_s4_2055_url = cdn + "/noise-contours/2055_N60N_S4_v2.geojson";
const n60_s4_2055_url = cdn + "/noise-contours/2055_N60_24h_S4.geojson";
// single events
const lamax_day_b738_url = cdn + "/noise-contours/LAMAX_Day_B738.geojson";
const lamax_day_b773_url = cdn + "/noise-contours/LAMAX_Day_B773.geojson";
const lamax_day_dh8d_url = cdn + "/noise-contours/LAMAX_Day_DH8D.geojson";
const lamax_night_b738_url = cdn + "/noise-contours/LAMAX_Night_B738.geojson";
const lamax_night_b773_url = cdn + "/noise-contours/LAMAX_Night_B773.geojson";
const lamax_rro_b738_url = cdn + "/noise-contours/LAMAX_RRO_B738_v2.geojson";
const lamax_rro_b773_url = cdn + "/noise-contours/LAMAX_RRO_B773_v2.geojson";
// noticeability layer
const noticeability_url = cdn + "/noise-contours/Noticeability.geojson";
// composite lamax
const composite_lamax_url = cdn + "/noise-contours/LAMAX.geojson";

// Non-WSI data
const bwu_arrivals_current_tracks_url =
  cdn + "/non-wsi-tracks/bwu_arrivals_current.geojson";
const bwu_arrivals_proposed_tracks_url =
  cdn + "/non-wsi-tracks/bwu_arrivals_proposed.geojson";
const bwu_departure11c_proposed_tracks_url =
  cdn + "/non-wsi-tracks/bwu_departure11c_proposed.geojson";
const bwu_departure29c_proposed_tracks_url =
  cdn + "/non-wsi-tracks/bwu_departure29c_proposed.geojson";
const syd_departure25_current_tracks_url =
  cdn + "/non-wsi-tracks/syd_departure25_current.geojson";
const syd_departure25_proposed_tracks_url =
  cdn + "/non-wsi-tracks/syd_departure25_proposed.geojson";
const syd_departure34l_current_tracks_url =
  cdn + "/non-wsi-tracks/syd_departure34l_current.geojson";
const syd_departure34l_proposed_tracks_url =
  cdn + "/non-wsi-tracks/syd_departure34l_proposed.geojson";
const syd_departurenj_proposed_tracks_url =
  cdn + "/non-wsi-tracks/syd_departurenj_proposed.geojson";

const bwu_arrivals_proposed_swathes_url =
  cdn + "/non-wsi-swathes/bwu_arrivals_proposed.geojson";
const bwu_departure11c_proposed_swathes_url =
  cdn + "/non-wsi-swathes/bwu_departure11c_proposed.geojson";
const bwu_departure29c_proposed_swathes_url =
  cdn + "/non-wsi-swathes/bwu_departure29c_proposed.geojson";
const syd_arrivals_current_swathes_url =
  cdn + "/non-wsi-swathes/syd_arrivals_current.geojson";
const syd_arrivals_proposed_swathes_url =
  cdn + "/non-wsi-swathes/syd_arrivals_proposed.geojson";
const syd_departure25_current_swathes_url =
  cdn + "/non-wsi-swathes/syd_departure25_current.geojson";
const syd_departure25_proposed_swathes_url =
  cdn + "/non-wsi-swathes/syd_departure25_proposed.geojson";
const syd_departure34l_current_swathes_url =
  cdn + "/non-wsi-swathes/syd_departure34l_current.geojson";
const syd_departure34l_proposed_swathes_url =
  cdn + "/non-wsi-swathes/syd_departure34l_proposed.geojson";
const syd_departurenj_current_swathes_url =
  cdn + "/non-wsi-swathes/syd_departurenj_current.geojson";
const syd_departurenj_proposed_swathes_url =
  cdn + "/non-wsi-swathes/syd_departurenj_proposed.geojson";

const syd_arrivals_proposed_vectorings_url =
  cdn + "/non-wsi-vectoring-areas/syd_arrivals_proposed.geojson";
const syd_departure25_current_vectorings_url =
  cdn + "/non-wsi-vectoring-areas/syd_departure25_current.geojson";
const syd_departure25_proposed_vectorings_url =
  cdn + "/non-wsi-vectoring-areas/syd_departure25_proposed.geojson";
const syd_departure34l_current_vectorings_url =
  cdn + "/non-wsi-vectoring-areas/syd_departure34l_current.geojson";
const syd_departure34l_proposed_vectorings_url =
  cdn + "/non-wsi-vectoring-areas/syd_departure34l_proposed.geojson";
const syd_departurenj_proposed_vectorings_url =
  cdn + "/non-wsi-vectoring-areas/syd_departurenj_proposed.geojson";

const syd_departure25_current_contours_url =
  cdn + "/non-wsi-noise-contours/syd_departure25_current.geojson";
const syd_departure25_proposed_contours_url =
  cdn + "/non-wsi-noise-contours/syd_departure25_proposed.geojson";
const syd_departure34l_current_contours_url =
  cdn + "/non-wsi-noise-contours/syd_departure34l_current.geojson";
const syd_departure34l_proposed_contours_url =
  cdn + "/non-wsi-noise-contours/syd_departure34l_proposed.geojson";

const bwu_runway_url = cdn + "/runways/bwu_runways.geojson";
const syd_runway_url = cdn + "/runways/syd_runways.geojson";

const bwu_boundaries_url = cdn + "/airport_boundaries/bwu_boundaries.geojson";
const syd_boundaries_url = cdn + "/airport_boundaries/syd_boundaries.geojson";

// Set the available cumulative noise contour combinations (using the indicated pattern for the keys)
const cumulativeNoisePattern = ["year", "scenario"];
const cumulativeNoiseCombinations = {
  "2033_s1": {
    anef_url: anec_s1_2033_url,
    n70_url: n70_s1_2033_url,
    n60n_url: n60n_s1_2033_url,
    lamax_url: composite_lamax_url,
    n60_url: n60_s1_2033_url,
  },
  "2033_s3": {
    anef_url: anec_s3_2033_url,
    n70_url: n70_s3_2033_url,
    n60n_url: n60n_s3_2033_url,
    lamax_url: composite_lamax_url,
    n60_url: n60_s3_2033_url,
  },
  "2033_s4": {
    anef_url: anec_s4_2033_url,
    n70_url: n70_s4_2033_url,
    n60n_url: n60n_s4_2033_url,
    lamax_url: composite_lamax_url,
    n60_url: n60_s4_2033_url,
  },
  "2040_s1": {
    anef_url: anec_s1_2040_url,
    n70_url: n70_s1_2040_url,
    n60n_url: n60n_s1_2040_url,
    lamax_url: composite_lamax_url,
    n60_url: n60_s1_2040_url,
  },
  "2040_s3": {
    anef_url: anec_s3_2040_url,
    n70_url: n70_s3_2040_url,
    n60n_url: n60n_s3_2040_url,
    lamax_url: composite_lamax_url,
    n60_url: n60_s3_2040_url,
  },
  "2040_s4": {
    anef_url: anec_s4_2040_url,
    n70_url: n70_s4_2040_url,
    n60n_url: n60n_s4_2040_url,
    lamax_url: composite_lamax_url,
    n60_url: n60_s4_2040_url,
  },
  "2055_s1": {
    anef_url: anec_s1_2055_url,
    n70_url: n70_s1_2055_url,
    n60n_url: n60n_s1_2055_url,
    lamax_url: composite_lamax_url,
    n60_url: n60_s1_2055_url,
  },
  "2055_s3": {
    anef_url: anec_s3_2055_url,
    n70_url: n70_s3_2055_url,
    n60n_url: n60n_s3_2055_url,
    lamax_url: composite_lamax_url,
    n60_url: n60_s3_2055_url,
  },
  "2055_s4": {
    anef_url: anec_s4_2055_url,
    n70_url: n70_s4_2055_url,
    n60n_url: n60n_s4_2055_url,
    lamax_url: composite_lamax_url,
    n60_url: n60_s4_2055_url,
  },
};

// Set the available single event noise contour combinations (using the indicated pattern for the keys)
const eventNoisePattern = ["eventNoise", "eventNoiseAircraft"];
const eventNoiseCombinations = {
  day_b738: lamax_day_b738_url,
  day_b773: lamax_day_b773_url,
  day_dh8d: lamax_day_dh8d_url,
  day_composite: composite_lamax_url,
  night_b738: lamax_night_b738_url,
  night_b773: lamax_night_b773_url,
  night_composite: composite_lamax_url,
  rro_b738: lamax_rro_b738_url,
  rro_b773: lamax_rro_b773_url,
  rro_composite: composite_lamax_url,
};

const altitudeSegments = {
  subtrack_id_100: subtrack_id_100,
  subtrack_id_109: subtrack_id_109,
  subtrack_id_110: subtrack_id_110,
  subtrack_id_120: subtrack_id_120,
  subtrack_id_135: subtrack_id_135,
  subtrack_id_144: subtrack_id_144,
  subtrack_id_151: subtrack_id_151,
  subtrack_id_173: subtrack_id_173,
  subtrack_id_18: subtrack_id_18,
  subtrack_id_194: subtrack_id_194,
  subtrack_id_198: subtrack_id_198,
  subtrack_id_20: subtrack_id_20,
  subtrack_id_207: subtrack_id_207,
  subtrack_id_220: subtrack_id_220,
  subtrack_id_230: subtrack_id_230,
  subtrack_id_246: subtrack_id_246,
  subtrack_id_257: subtrack_id_257,
  subtrack_id_270: subtrack_id_270,
  subtrack_id_282: subtrack_id_282,
  subtrack_id_285: subtrack_id_285,
  subtrack_id_295: subtrack_id_295,
  subtrack_id_305: subtrack_id_305,
  subtrack_id_310: subtrack_id_310,
  subtrack_id_315: subtrack_id_315,
  subtrack_id_328: subtrack_id_328,
  subtrack_id_339: subtrack_id_339,
  subtrack_id_354: subtrack_id_354,
  subtrack_id_359: subtrack_id_359,
  subtrack_id_36: subtrack_id_36,
  subtrack_id_364: subtrack_id_364,
  subtrack_id_37: subtrack_id_37,
  subtrack_id_400: subtrack_id_400,
  subtrack_id_417: subtrack_id_417,
  subtrack_id_455: subtrack_id_455,
  subtrack_id_46: subtrack_id_46,
  subtrack_id_471: subtrack_id_471,
  subtrack_id_482: subtrack_id_482,
  subtrack_id_5: subtrack_id_5,
  subtrack_id_502: subtrack_id_502,
  subtrack_id_517: subtrack_id_517,
  subtrack_id_573: subtrack_id_573,
  subtrack_id_615: subtrack_id_615,
  subtrack_id_625: subtrack_id_625,
  subtrack_id_6251: subtrack_id_6251,
  subtrack_id_6258: subtrack_id_6258,
  subtrack_id_6270: subtrack_id_6270,
  subtrack_id_6281: subtrack_id_6281,
  subtrack_id_85: subtrack_id_85,
  subtrack_id_92: subtrack_id_92,
  subtrack_id_50000: subtrack_id_50000,
  subtrack_id_50001: subtrack_id_50001,
  subtrack_id_50002: subtrack_id_50002,
  subtrack_id_50003: subtrack_id_50003,
  subtrack_id_50004: subtrack_id_50004,
  subtrack_id_50005: subtrack_id_50005,
  subtrack_id_50006: subtrack_id_50006,
  subtrack_id_50007: subtrack_id_50007,
  subtrack_id_50008: subtrack_id_50008,
  subtrack_id_50009: subtrack_id_50009,
};

// Set the available flight path combinations (using the indicated pattern for the keys)
const trackPattern = ["time", "runway"];
const trackCombinations = {
  day_runway05: {
    arrivals: runway05_day_arrival_url,
    arrivalsBackbones: runway05_day_arrival_line_url,
    departures: runway05_day_departure_url,
    departuresBackbones: runway05_day_departure_line_url,
    departureVectoring: vectoring_05_departure_day_url,
  },
  day_runway23: {
    arrivals: runway23_day_arrival_url,
    arrivalsBackbones: runway23_day_arrival_line_url,
    departures: runway23_day_departure_url,
    departuresBackbones: runway23_day_departure_line_url,
    departureVectoring: vectoring_23_departure_day_url,
  },
  night_runway05: {
    arrivals: runway05_night_arrival_url,
    arrivalsBackbones: runway05_night_arrival_line_url,
    departures: runway05_night_departure_url,
    departuresBackbones: runway05_night_departure_line_url,
  },
  night_runway23: {
    arrivals: runway23_night_arrival_url,
    arrivalsBackbones: runway23_night_arrival_line_url,
    departures: runway23_night_departure_url,
    departuresBackbones: runway23_night_departure_line_url,
    arrivalVectoring: vectoring_23_arrival_night_url,
  },
  night_rro: {
    arrivals: rro_night_arrival_url,
    arrivalsBackbones: rro_night_arrival_line_url,
    departures: rro_night_departure_url,
    departuresBackbones: rro_night_departure_line_url,
  },
  nightnap_rro: {
    arrivals: naprro_night_arrival_url,
    arrivalsBackbones: naprro_night_arrival_line_url,
    departures: naprro_night_departure_url,
    departuresBackbones: naprro_night_departure_line_url,
    departureVectoring: vectoring_rro_departure_nightnap_url,
  },
};

// Set the available airport combinations (using the indicated pattern for the keys)
const airportPattern = ["airportChange", "procedureGroup"];
const airportCombinations = {
  // Bankstown Airport
  bankstown_dep29: {
    runway: bwu_runway_url,
    airportBoundaries: bwu_boundaries_url,
    proposedTrack: bwu_departure29c_proposed_tracks_url,
    proposedSwathe: bwu_departure29c_proposed_swathes_url,
  },
  bankstown_dep11: {
    runway: bwu_runway_url,
    airportBoundaries: bwu_boundaries_url,
    proposedTrack: bwu_departure11c_proposed_tracks_url,
    proposedSwathe: bwu_departure11c_proposed_swathes_url,
  },
  bankstown_arrivals: {
    runway: bwu_runway_url,
    airportBoundaries: bwu_boundaries_url,
    currentTrack: bwu_arrivals_current_tracks_url,
    proposedTrack: bwu_arrivals_proposed_tracks_url,
    proposedSwathe: bwu_arrivals_proposed_swathes_url,
  },
  // Sydney (Kingsford Smith) Airport
  kingsfordsmith_dep34l: {
    runway: syd_runway_url,
    airportBoundaries: syd_boundaries_url,
    currentTrack: syd_departure34l_current_tracks_url,
    currentSwathe: syd_departure34l_current_swathes_url,
    currentVectoring: syd_departure34l_current_vectorings_url,
    currentContours: syd_departure34l_current_contours_url,
    proposedTrack: syd_departure34l_proposed_tracks_url,
    proposedSwathe: syd_departure34l_proposed_swathes_url,
    proposedVectoring: syd_departure34l_proposed_vectorings_url,
    proposedContours: syd_departure34l_proposed_contours_url,
  },
  kingsfordsmith_dep25: {
    runway: syd_runway_url,
    airportBoundaries: syd_boundaries_url,
    currentTrack: syd_departure25_current_tracks_url,
    currentSwathe: syd_departure25_current_swathes_url,
    currentVectoring: syd_departure25_current_vectorings_url,
    currentContours: syd_departure25_current_contours_url,
    proposedTrack: syd_departure25_proposed_tracks_url,
    proposedSwathe: syd_departure25_proposed_swathes_url,
    proposedVectoring: syd_departure25_proposed_vectorings_url,
    proposedContours: syd_departure25_proposed_contours_url,
  },
  kingsfordsmith_depnj: {
    runway: syd_runway_url,
    airportBoundaries: syd_boundaries_url,
    currentSwathe: syd_departurenj_current_swathes_url,
    proposedTrack: syd_departurenj_proposed_tracks_url,
    proposedSwathe: syd_departurenj_proposed_swathes_url,
    proposedVectoring: syd_departurenj_proposed_vectorings_url,
  },
  kingsfordsmith_arrivals: {
    runway: syd_runway_url,
    airportBoundaries: syd_boundaries_url,
    currentSwathe: syd_arrivals_current_swathes_url,
    proposedSwathe: syd_arrivals_proposed_swathes_url,
    proposedVectoring: syd_arrivals_proposed_vectorings_url,
  },
};

export {
  airportPattern,
  airportCombinations,
  cumulativeNoisePattern,
  cumulativeNoiseCombinations,
  eventNoisePattern,
  eventNoiseCombinations,
  trackPattern,
  trackCombinations,
  altitudeSegments,
  runway_url,
  runway_label_url,
  noticeability_url,
  airport_boundaries_url,
};
