const filterNonJetPaths = (data, onlyNonJets = true) => {
  return {
    ...data,
    features: data.features.filter(({ properties }) => {
      const isNonJet = properties.reference_name.includes("Non-Jet");
      return onlyNonJets ? isNonJet : !isNonJet;
    }),
  };
};

export { filterNonJetPaths };
