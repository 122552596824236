export const NOISE_SCENARIOS = {
  year: {
    year2033: {
      url: "2033",
      sectionTitle: "Year 2033​",
    },
    year2040: {
      url: "2040",
      sectionTitle: "Year 2040​​",
    },
    year2055: {
      url: "2055",
      sectionTitle: "Year 2055​​",
    },
  },
  scenario: {
    noPreference: {
      url: "s1",
      name: "No Runway Preference",
    },
    prefer05: {
      url: "s3",
      name: "Prefer Runway 05",
    },
    prefer23: {
      url: "s4",
      name: "Prefer Runway 23",
    },
  },
  metric: {
    anec: {
      url: "anef",
      name: "ANEC",
    },
    n60: {
      url: "n60",
      name: "N60 (Day)",
    },
    n70: {
      url: "n70",
      name: "N70 (Day)",
    },
    n60n: {
      url: "n60n",
      name: "N60 (Night)",
    },
  },
  lamax: {
    withinContour:
      "This location is predicted to experience a maximum noise level of {db} decibels during a typical overflight by the aircraft types expected to use WSI.​",
    outsideContour:
      "This location falls outside of the selected noise contours for WSI, however you may still see and hear aircraft flying to and from WSI at a noise level of around 42 decibels.​",
  },
};

export const TRACK_SCENARIOS = {
  time: {
    day: {
      url: "day",
      titleName: "Day-evening",
      descriptionName: "Day-Evening",
      timeRange: "(5:30am – 11:00pm)​",
    },
    night: {
      url: "night",
      titleName: "Night-time",
      descriptionName: "Overnight",
      timeRange: "(11:00pm – 5:30am)​",
    },
  },
  runway: {
    runway05: {
      url: "runway05",
      name: "Runway 05",
    },
    runway23: {
      url: "runway23",
      name: "Runway 23",
    },
    rro: {
      url: "rro",
      name: "RRO",
    },
    rro_nap: {
      url: "nap_rro",
      name: "RRO-NAP",
    },
  },
  type: {
    arrivals: "arrivals",
    departures: "departures",
  },
};
