import { Source, Layer } from "react-map-gl";
import { filterNonJetPaths } from "./utils";

function DepartureArrowLayer({
  layerId,
  beforeId,
  query,
  selectedArrivalNumbers,
  selectedDepartureNumbers,
}) {
  // Check if there are any selected tracks
  let noSelection =
    selectedArrivalNumbers.length === 0 &&
    selectedDepartureNumbers.length === 0;

  // Get the arrival data
  const { isError, data, error } = query;

  if (isError) {
    console.error(error);
    return <></>;
  }

  const filteredData = filterNonJetPaths(data, false);

  return (
    <Source
      id="departure-track-arrows"
      type="geojson"
      lineMetrics={true}
      data={filteredData}
    >
      <Layer
        source="departure-track-arrows"
        id={layerId}
        beforeId={beforeId}
        type="symbol"
        paint={{
          "icon-opacity": 1,
        }}
        layout={{
          "text-rotation-alignment": "map",
          "symbol-placement": "line",
          "symbol-spacing": 50,
          "icon-rotate": -90,
          "icon-image": "arrow",
        }}
        filter={[
          "any",
          noSelection,
          ["in", ["get", "SUBTRACK_ID"], ["literal", selectedDepartureNumbers]],
        ]}
      />
    </Source>
  );
}

export default DepartureArrowLayer;
