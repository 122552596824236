import { useQuery } from "@tanstack/react-query";
import booleanPointInPolygon from "@turf/boolean-point-in-polygon";
import { polygon } from "@turf/helpers";
import i18next from "../../../i18n";
import getData from "../../../requests/getData";
import {
  cumulativeNoiseCombinations,
  cumulativeNoisePattern,
} from "../../../requests/register";
import "../../../styles/map/MapComponent.css";
import NoiseSearchComponent from "./NoiseSearchComponent";
import Loader from "./Loader";

function NoticeabilityComponent({
  noticeability,
  filterSelection,
  noiseMetric,
  turfPoint,
}) {
  // Get cumulative LAmax for the current scenario
  let combination_id = cumulativeNoisePattern
    .map((key) => filterSelection[key])
    .join("_");
  let combination = cumulativeNoiseCombinations[combination_id];
  let lamaxUrl = combination["LAmax".toLowerCase() + "_url"];

  // Get the arrival data
  const { isLoading, isError, data, error } = useQuery({
    queryKey: ["lamax", lamaxUrl],
    queryFn: async () => {
      const data = await getData(lamaxUrl);
      return data;
    },
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    console.error(error);
    return <></>;
  }

  // Check default noticeability layers
  let noticeabilityPolygon = null;
  noticeability.features.forEach((feature) => {
    const turfPolygon = polygon(feature.geometry.coordinates);
    const isInPolygon = booleanPointInPolygon(turfPoint, turfPolygon);
    if (isInPolygon === true) {
      noticeabilityPolygon = feature.properties["Contour"];
    }
  });

  // Check if current position falls under any contour of the cumulative LAmax
  let lamaxFeatureFound = null;
  data.features.forEach((laMaxFeature) => {
    const turfPolygon = polygon(laMaxFeature.geometry.coordinates);
    const isInPolygon = booleanPointInPolygon(turfPoint, turfPolygon);
    if (isInPolygon === true) {
      lamaxFeatureFound = laMaxFeature.properties.contour;
    }
  });

  // Choose the highest of the contours found in the selected location
  let layer;
  if (lamaxFeatureFound !== null) {
    layer = Math.max(noticeabilityPolygon, lamaxFeatureFound);
  } else {
    layer = noticeabilityPolygon;
  }

  let noiseText;
  if (layer !== null) {
    noiseText = i18next.t("popup.noise.noticeable", {
      metric: noiseMetric,
      db: layer,
    });
  } else {
    noiseText = i18next.t("popup.noise.unnoticeable", { metric: noiseMetric });
  }

  return <NoiseSearchComponent noiseText={noiseText} />;
}

export default NoticeabilityComponent;
