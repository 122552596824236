import { Source, Layer } from "react-map-gl";
import { getRunway } from "../../../requests/getData";
import { runway_color } from "../../../colors";

import { useQuery } from "@tanstack/react-query";

function RunwayLayer() {
  const { isError, data, error } = useQuery({
    queryKey: ["runways"],
    queryFn: getRunway,
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    placeholderData: {
      type: "FeatureCollection",
      features: [],
    },
  });

  if (isError) {
    console.error(error);
    return <></>;
  }

  return (
    <Source id="runway-data" type="geojson" data={data}>
      <Layer
        id="runway-layer"
        type="fill"
        minzoom={0}
        paint={{
          "fill-color": runway_color, // red color fill
          "fill-opacity": 1,
        }}
      />
    </Source>
  );
}

export default RunwayLayer;
