import { Source, Layer } from "react-map-gl";
import getData from "../../../requests/getData";
import { useQuery } from "@tanstack/react-query";
import {
  departure_vector_area_selected_opacity,
  departure_vector_area_unselected_opacity,
  departure_track_selected_opacity,
} from "../../../colors";

function DepartureVectoringLayer({
  layerId,
  layerNestedId,
  beforeId,
  departureVectoringUrl,
  setDepartureVectoring,
  selectedDepartureVectoringIndices,
}) {
  const { isError, data } = useQuery({
    queryKey: ["departureVectoring", departureVectoringUrl],
    queryFn: async () => {
      const data = await getData(departureVectoringUrl);
      return data;
    },
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: !!departureVectoringUrl,
    placeholderData: {
      type: "FeatureCollection",
      features: [],
    },
    onSuccess: (data) => {
      setDepartureVectoring(data);
    },
    onError: (error) => {
      console.error(error);
    },
  });

  if (isError || !data) {
    return <></>;
  }

  const selected = selectedDepartureVectoringIndices?.length > 0;

  const dataNestedEnhanced = {
    ...data,
    features: data.features
      .filter((feature) => feature.properties.nested_track.endsWith("Nested"))
      .map((feature) => {
        return {
          ...feature,
          properties: {
            ...feature.properties,
            opacitySelected: departure_vector_area_selected_opacity,
            opacityUnselected: feature.properties.nested_track.endsWith(
              "Nested"
            )
              ? departure_vector_area_unselected_opacity
              : 0,
          },
        };
      }),
  };

  const dataFiltered = {
    ...data,
    features: data.features.filter(
      (feature) => !feature.properties.nested_track.endsWith("Nested")
    ),
  };

  return (
    <>
      <Source id="departure-vectoring-areas" type="geojson" data={dataFiltered}>
        <Layer
          source="departure-vectoring-areas"
          id={layerId}
          beforeId={beforeId}
          type="fill"
          paint={{
            "fill-pattern": "shadingDepartures",
            "fill-opacity": departure_track_selected_opacity,
          }}
          filter={[
            "in",
            ["get", "index"],
            ["literal", selectedDepartureVectoringIndices],
          ]}
        />
      </Source>
      <Source
        id="departure-vectoring-nested-areas"
        type="geojson"
        data={dataNestedEnhanced}
      >
        <Layer
          source="departure-vectoring-nested-areas"
          id={layerNestedId}
          beforeId={beforeId}
          type="fill"
          paint={{
            "fill-color": "#d5c2f0",
            "fill-opacity": selected
              ? ["get", "opacitySelected"]
              : ["get", "opacityUnselected"],
          }}
        />
      </Source>
    </>
  );
}

export default DepartureVectoringLayer;
