import { Source, Layer } from "react-map-gl";
import { useArrivalArrowsQuery } from "./useLayerQueries";

function ArrivalArrowLayer({
  layerId,
  beforeId,
  arrivalArrowUrl,
  selectedArrivalNumbers,
  selectedDepartureNumbers,
}) {
  // Check if there are any selected tracks
  let noSelection =
    selectedArrivalNumbers.length === 0 &&
    selectedDepartureNumbers.length === 0;

  // Get the arrival data
  const { isError, data } = useArrivalArrowsQuery(arrivalArrowUrl);

  if (isError) {
    return <></>;
  }

  return (
    <Source
      id="arrival-track-arrows"
      type="geojson"
      lineMetrics={true}
      data={data}
    >
      <Layer
        source="arrival-track-arrows"
        id={layerId}
        beforeId={beforeId}
        type="symbol"
        paint={{
          "icon-opacity": 1,
        }}
        layout={{
          "text-rotation-alignment": "map",
          "symbol-placement": "line",
          "symbol-spacing": 50,
          "icon-rotate": -90,
          "icon-image": "arrow",
        }}
        filter={[
          "any",
          noSelection,
          ["in", ["get", "SUBTRACK_ID"], ["literal", selectedArrivalNumbers]],
        ]}
      />
    </Source>
  );
}

export default ArrivalArrowLayer;
