import React from "react";
import { Page, Text, View } from "@react-pdf/renderer";
import { TRACK_SCENARIOS } from "../../scenarios";
import styles from "../../documentStyles";
import { TrackPageHeader } from "../sections/PageHeader";
import { TracksTable } from "../sections/Table";
import PageFooter from "../sections/PageFooter";

const getDescriptionText = (movements, time) => {
  if (movements.departures.max > 0 && movements.arrivals.max > 0) {
    return (
      <Text style={styles.sectionDescription}>
        An average of {movements.departures.average} departures and{" "}
        {movements.arrivals.average} arrivals, up to a maximum of{" "}
        {movements.departures.max} departures and {movements.arrivals.max}{" "}
        arrivals, could overfly this location during the{" "}
        {TRACK_SCENARIOS.time[time].descriptionName} period when this runway
        operating mode is in use.​
      </Text>
    );
  }
  if (movements.departures.max > 0 && movements.arrivals.max === 0) {
    return (
      <Text style={styles.sectionDescription}>
        An average of {movements.departures.average} departures, up to a maximum
        of {movements.departures.max} departures, could overfly this location
        during the {TRACK_SCENARIOS.time[time].descriptionName} period when this
        runway operating mode is in use.​
      </Text>
    );
  }
  if (movements.departures.max === 0 && movements.arrivals.max > 0) {
    return (
      <Text style={styles.sectionDescription}>
        An average of {movements.arrivals.average} arrivals, up to a maximum of{" "}
        {movements.arrivals.max} arrivals, could overfly this location during
        the {TRACK_SCENARIOS.time[time].descriptionName} period when this runway
        operating mode is in use.​
      </Text>
    );
  }
};

const getSpecialVectoringAreaText = () => {
  return (
    <Text style={styles.sectionDescription}>
      When RRO mode is available and there are no arriving aircraft within 30 nm
      (55.5 km) of the runway, aircraft taking off in a south-west direction
      flying to destinations in the north and west would be kept on runway
      heading for longer. Air Traffic Control will then direct aircraft to fly
      anywhere in the shaded area before joining their en-route flight path.
    </Text>
  );
};

const TracksPage = ({ adressInfo, completeTracksReport }) => {
  return (
    <Page size="A4">
      <TrackPageHeader adressInfo={adressInfo} />
      <View style={styles.container}>
        <View style={styles.sectionWrapper}>
          {Object.entries(completeTracksReport).map(([time, report]) => (
            <View style={styles.section} key={`tracks_${time}`}>
              <View style={styles.sectionContent}>
                <Text style={styles.sectionTitle}>
                  {TRACK_SCENARIOS.time[time].titleName} movements
                </Text>
                <Text style={styles.sectionSubtitle}>
                  {TRACK_SCENARIOS.time[time].timeRange}
                </Text>
                {Object.entries(report).map(([runway, runwayReport]) => (
                  <View key={`tracks_${time}_${runway}`} wrap={false}>
                    <Text style={styles.sectionSubtitleBody}>
                      When {TRACK_SCENARIOS.runway[runway].name} is in use:
                    </Text>
                    {runwayReport.specialVectoringAbove === true ? (
                      getSpecialVectoringAreaText()
                    ) : runwayReport.tracks.length > 0 ? (
                      <>
                        {getDescriptionText(runwayReport.movements, time)}
                        <Text style={styles.tableTitle}>
                          The following WSI flight paths overfly this location:​
                        </Text>
                        <TracksTable
                          tableContent={runwayReport.tracks}
                          tableKey={`tracks_${time}_${runway}`}
                        />
                      </>
                    ) : (
                      <Text style={styles.sectionDescription}>
                        This location is within{" "}
                        {runwayReport.closestTrack.distance} km of a WSI flight
                        path ({runwayReport.closestTrack.tracks}). It is
                        recommended that you double-click the closest flight
                        path in the Noise Tool to understand the frequency of
                        flights expected nearby.
                      </Text>
                    )}
                  </View>
                ))}
              </View>
            </View>
          ))}
        </View>
      </View>
      <PageFooter />
    </Page>
  );
};

export default TracksPage;
