import { Source, Layer } from "react-map-gl";
import getData from "../../../requests/getData";
import { useQuery } from "@tanstack/react-query";
import { arrival_track_selected_opacity } from "../../../colors";

function ArrivalVectoringLayer({
  layerId,
  beforeId,
  arrivalVectoringUrl,
  setArrivalVectoring,
  selectedArrivalVectoringIndices,
}) {
  const { isError, data, error } = useQuery({
    queryKey: ["arrivalVectoring", arrivalVectoringUrl],
    queryFn: async () => {
      const data = await getData(arrivalVectoringUrl);
      return data;
    },
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    placeholderData: {
      type: "FeatureCollection",
      features: [],
    },
    onSuccess: (data) => {
      setArrivalVectoring(data);
    },
    onError: (error) => {
      console.error(error);
    },
  });

  if (isError) {
    console.error(error);
    return <></>;
  }

  return (
    <Source id="arrival-vectoring-areas" type="geojson" data={data}>
      <Layer
        source="arrival-vectoring-areas"
        id={layerId}
        beforeId={beforeId}
        type="fill"
        paint={{
          "fill-pattern": "shadingArrivals",
          "fill-opacity": arrival_track_selected_opacity,
        }}
        filter={[
          "in",
          ["get", "index"],
          ["literal", selectedArrivalVectoringIndices],
        ]}
      />
    </Source>
  );
}

export default ArrivalVectoringLayer;
