const version_string = "1.2.1-" + process.env.REACT_APP_GIT_SHA;

const changeLog = [
  {
    date: "2024-11-07",
    text: [
      "Added new ‘Overnight (NAP active)’ time of day.",
      "Added information to highlight changes between draft and final EIS.",
      "Added mobile specific introduction screens.",
      "Various small changes to the design and text elements of the tool.",
    ],
  },
  {
    date: "2023-12-20",
    text: [
      "Improved POI search results", // WSI-3
    ],
  },
  {
    date: "2023-12-19",
    text: [
      "Added new report generation feature summarising WSI flight path and noise forecasts for a specific location", // WSI-4
      "Various small changes to the design and texts", // WSI-8 + WSI-12 + WSI-13
    ],
  },
  {
    date: "2023-11-24",
    text: [
      "Fix bug with flight counts", // WSI-11 + WSI-10
    ],
  },
  {
    date: "2023-10-25",
    text: [
      "Fix bug with listed flight paths under transition areas", // #405
      "Fix bug with buttons to noise contours which do not exist", // #403
      "Various small changes to the design of the tool", // #311
    ],
  },
  {
    date: "2023-10-24",
    text: [
      "Added new mode showing Sydney and Bankstown Airport changes.", // #404
      "Added WSI noise information for 2055 airport development year", // #404
      // "Sydney Basin flight paths", // #45
      "Added WSI composite LAmax contours", // #354
      "Various small changes to the design of the tool.", // #394 #392
    ],
  },
  {
    date: "2023-08-14",
    text: [
      "Renamed ongoing technical issues and removed from landing page.", // #315
      "Various small changes to the design of the tool.", // #282
    ],
  },
  {
    date: "2023-07-04",
    text: [
      "Updated ongoing technical issues.", // #304
      "Made various optimizations to service delivery.", // #310
      "Small fixes to the design of the tool.", // #308
      "Various small changes to the design of the tool.", // #312
    ],
  },
  {
    date: "2023-06-28",
    text: [
      "Add text to list ongoing technical issues.", // #304 #305
      "Made various optimizations to service delivery.", // #307 #294 !236
      "Fix issue with double tapping on non-Apple touch screen devices.", // #292
      "Fix issue with CDN triggering anti-virus protection.", // #301
      "Fix issue with transitioning areas incorrectly being added to the movement count.", // #290
      "Fix issue with vectoring areas not being highlighted after using search box.", // #235
      "Various small changes to the design of the tool.", // #288 #286 #287 #293 #296 #306
    ],
  },
  {
    date: "2023-06-27",
    text: ["Initial release."],
  },
];

export { changeLog };

export default version_string;
