import { useQuery } from "@tanstack/react-query";
import { Layer, Source } from "react-map-gl";
import getData from "../../../requests/getData";

function ChangeContourLayer({
  layerId,
  beforeId,
  contourUrl,
  changeType,
  selectedNonWsiContour,
}) {
  const currentType = changeType === "current";
  const proposedType = changeType === "proposed";

  // Get the selected contour
  const contourId = selectedNonWsiContour["track_id"];
  const contourMetric = selectedNonWsiContour["metric"];

  if (!(currentType || proposedType)) {
    console.error(
      changeType +
        " (changeType) is not valid. It should be 'current' or 'proposed'."
    );
  }

  // Get the contour data
  const { isError, data, error } = useQuery({
    queryKey: ["changeContour", contourUrl],
    queryFn: async () => {
      const data = await getData(contourUrl);
      return data;
    },
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    placeholderData: {
      type: "FeatureCollection",
      features: [],
    },
    onError: (error) => {
      console.error(error);
    },
  });

  if (isError) {
    console.error(error);
    return <></>;
  }

  // Set the source id
  const sourceId = layerId + "-data";

  // Determine the layer ids
  const fillLayerId = layerId + "-fill";
  const outlineBeforeId = beforeId;

  return (
    <Source id={sourceId} type="geojson" data={data}>
      {proposedType && (
        <Layer
          source={sourceId}
          id={fillLayerId}
          beforeId={beforeId}
          type="fill"
          paint={{
            "fill-color": ["get", "fill-color"],
            "fill-opacity": ["get", "fill-opacity"],
          }}
          filter={[
            "all",
            ["==", ["get", "metric"], contourMetric],
            ["==", ["get", "track_id"], contourId],
          ]}
        />
      )}
      <Layer
        source={sourceId}
        id={layerId}
        beforeId={outlineBeforeId}
        type="line"
        paint={{
          "line-width": ["get", "outline-weight"],
          "line-color": ["get", "outline-color"],
          "line-opacity": ["get", "outline-opacity"],
        }}
        filter={[
          "all",
          ["==", ["get", "metric"], contourMetric],
          ["==", ["get", "track_id"], contourId],
        ]}
      />
    </Source>
  );
}

export default ChangeContourLayer;
