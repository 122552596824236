import DepartureArrowLayer from "./DepartureArrowLayer";
import ArrivalArrowLayer from "./ArrivalArrowLayer";
import DepartureLayer from "./DepartureLayer";
import ArrivalLayer from "./ArrivalLayer";
import ArrivalVectoringLayer from "./ArrivalVectoringLayer";
import DepartureVectoringLayer from "./DepartureVectoringLayer";
import FlightTrackAltitudeLayer from "./FlightPathAltitudeLayer";
import { useDepartureArrowsQuery } from "./useLayerQueries";
import PathLineLayer from "./PathLineLayer";

const layerId = {
  arrival: {
    arrows: "arrival-track-arrows-layer",
    swathes: "arrival-swathe-layer",
    vectors: "arrival-vectoring-layer",
  },
  departure: {
    arrows: "departure-track-arrows-layer",
    swathes: "departure-swathe-layer",
    vectors: "departure-vectoring-layer",
    vectors_nested: "departure-vectoring-nested-layer",
  },
};

function FlightLayers({
  arrivalUrl,
  departureUrl,
  arrivalArrowUrl,
  departureArrowUrl,
  setArrivalPaths,
  setDeparturePaths,
  flightPathAltitudeIds,
  selectedArrivalNumbers,
  selectedDepartureNumbers,
  arrivalVectoringUrl,
  setArrivalVectoring,
  selectedArrivalVectoringIndices,
  departureVectoringUrl,
  setDepartureVectoring,
  selectedDepartureVectoringIndices,
}) {
  const departureArrowsQuery = useDepartureArrowsQuery(departureArrowUrl);

  // Create helper variables
  const showDepartureArrows = departureArrowUrl !== undefined;
  const showDepartures = departureUrl !== undefined;
  const showDepartureVectorings = departureVectoringUrl !== undefined;
  const showArrivalArrows = arrivalArrowUrl !== undefined;
  const showArrivals = arrivalUrl !== undefined;
  const showArrivalVectorings = arrivalVectoringUrl !== undefined;

  // Set the layer order and whether they are available or not
  let layerOrder = [
    { layer: "runway-layer", available: true },
    { layer: layerId.departure.arrows, available: showDepartureArrows },
    { layer: layerId.departure.swathes, available: showDepartures },
    { layer: layerId.departure.vectors, available: showDepartureVectorings },
    { layer: layerId.departure.vectors_nested, available: true },
    { layer: layerId.arrival.arrows, available: showArrivalArrows },
    { layer: layerId.arrival.swathes, available: showArrivals },
    { layer: layerId.arrival.vectors, available: showArrivalVectorings },
  ];

  // Remove unavailable layers
  const availableLayers = layerOrder
    .filter((o) => o.available)
    .map((o) => o.layer);

  function getBeforeId(layerName) {
    const index = availableLayers.indexOf(layerName);
    if (index > 0) {
      return availableLayers[index - 1];
    }
    return null;
  }

  return (
    <>
      {/* Departure Arrows */}
      {showDepartureArrows && (
        <>
          <PathLineLayer
            query={departureArrowsQuery}
            selectedArrivalNumbers={selectedArrivalNumbers}
            selectedDepartureNumbers={selectedDepartureNumbers}
          />
          <DepartureArrowLayer
            layerId={layerId.departure.arrows}
            beforeId={getBeforeId(layerId.departure.arrows)}
            query={departureArrowsQuery}
            selectedArrivalNumbers={selectedArrivalNumbers}
            selectedDepartureNumbers={selectedDepartureNumbers}
          />
        </>
      )}

      {/* Departure Swathes */}
      {showDepartures && (
        <DepartureLayer
          layerId={layerId.departure.swathes}
          beforeId={getBeforeId(layerId.departure.swathes)}
          departureUrl={departureUrl}
          setDeparturePaths={setDeparturePaths}
          selectedArrivalNumbers={selectedArrivalNumbers}
          selectedDepartureNumbers={selectedDepartureNumbers}
        />
      )}
      {/* Departure Vectoring Areas */}
      <DepartureVectoringLayer
        layerId={layerId.departure.vectors}
        layerNestedId={layerId.departure.vectors_nested}
        beforeId={getBeforeId(layerId.departure.vectors)}
        departureVectoringUrl={departureVectoringUrl}
        setDepartureVectoring={setDepartureVectoring}
        selectedDepartureVectoringIndices={selectedDepartureVectoringIndices}
      />
      {/* Arrival Arrows */}
      {showArrivalArrows && (
        <ArrivalArrowLayer
          layerId={layerId.arrival.arrows}
          beforeId={getBeforeId(layerId.arrival.arrows)}
          arrivalArrowUrl={arrivalArrowUrl}
          selectedArrivalNumbers={selectedArrivalNumbers}
          selectedDepartureNumbers={selectedDepartureNumbers}
        />
      )}

      {/* Arrival Swathes */}
      {showArrivals && (
        <ArrivalLayer
          layerId={layerId.arrival.swathes}
          beforeId={getBeforeId(layerId.arrival.swathes)}
          arrivalUrl={arrivalUrl}
          setArrivalPaths={setArrivalPaths}
          selectedArrivalNumbers={selectedArrivalNumbers}
          selectedDepartureNumbers={selectedDepartureNumbers}
        />
      )}

      {/* Arrival Vectoring Areas */}
      {showArrivalVectorings && (
        <ArrivalVectoringLayer
          layerId={layerId.arrival.vectors}
          beforeId={getBeforeId(layerId.arrival.vectors)}
          arrivalVectoringUrl={arrivalVectoringUrl}
          setArrivalVectoring={setArrivalVectoring}
          selectedArrivalVectoringIndices={selectedArrivalVectoringIndices}
        />
      )}

      {/* Flight Track Altitude labels */}
      {flightPathAltitudeIds && (
        <FlightTrackAltitudeLayer
          flightTracksSegmentIds={flightPathAltitudeIds}
        />
      )}
    </>
  );
}

export default FlightLayers;
