import { useQuery } from "@tanstack/react-query";
import React from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import i18next from "../../../i18n";
import getData from "../../../requests/getData";
import "../../../styles/map/Legend.css";
import LegendItem from "./LegendItem";

function ChangeContourLegend({
  legendDescription,
  setLegendDescription,
  selectedNonWsiContour,
  contourUrl,
}) {
  // Get the translation key for the title
  const legendTitle =
    "legend.noise." + selectedNonWsiContour.metric.toLowerCase();

  const legendInfo =
    "legend.airportChange.noise." +
    selectedNonWsiContour.metric.toLowerCase() +
    ".info";

  return (
    <>
      <div className="d-flex align-items-center justify-content-between w-100">
        <dt id="legend-title">{i18next.t(legendTitle)}</dt>
        <AiOutlineInfoCircle
          color={"white"}
          className="legend-info-icon"
          onClick={() => {
            setLegendDescription(!legendDescription);
          }}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setLegendDescription(!legendDescription);
            }
          }}
        />
      </div>
      {legendDescription && (
        <div className="legend-info-text">{i18next.t(legendInfo)}</div>
      )}
      <NonWsiContourItems
        contourUrl={contourUrl}
        selectedNonWsiContour={selectedNonWsiContour}
      />
    </>
  );
}

function NonWsiContourItems({ contourUrl, selectedNonWsiContour }) {
  // Get the contour data
  const { isError, data, error } = useQuery({
    queryKey: ["changeContour", contourUrl],
    queryFn: async () => {
      const data = await getData(contourUrl);
      return data;
    },
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    placeholderData: {
      type: "FeatureCollection",
      features: [],
    },
    onError: (error) => {
      console.error(error);
    },
  });

  if (isError) {
    console.error(error);
    return <></>;
  }

  // Extract the relevant properties from the contour data
  const properties = data.features
    .map((f) => f.properties)
    .filter(
      (p) =>
        p.metric === selectedNonWsiContour.metric &&
        p.track_id === selectedNonWsiContour.track_id
    );

  // Get the unique labels
  const uniqueLabels = getUniqueLabels(properties);

  return (
    <>
      {Object.entries(uniqueLabels).map(([k, p]) => (
        <LegendItem key={k} name={p["value"]} color={p["fill-color"]} />
      ))}
    </>
  );
}

export function getUniqueLabels(objects) {
  const uniqueLabels = {};
  for (const obj of objects) {
    uniqueLabels[obj.value] = obj;
  }
  return uniqueLabels;
}

export default ChangeContourLegend;
