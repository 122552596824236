import { Source, Layer } from "react-map-gl";
import getData from "../../../requests/getData";
import { useQuery } from "@tanstack/react-query";
import {
  departure_track_color,
  departure_track_opacity,
  departure_track_selected_opacity,
  departure_track_unselected_opacity,
} from "../../../colors";
import { filterNonJetPaths } from "./utils";

function DepartureLayer({
  layerId,
  beforeId,
  departureUrl,
  setDeparturePaths,
  selectedArrivalNumbers,
  selectedDepartureNumbers,
}) {
  const departureStyle = {
    type: "fill",
    paint: {
      "fill-color": departure_track_color,
      "fill-opacity": departure_track_opacity,
      "fill-selected-opacity": departure_track_selected_opacity,
      "fill-unselected-opacity": departure_track_unselected_opacity,
    },
    layout: {
      visibility: "visible",
    },
  };

  // Get the arrival data
  const { isError, data, error } = useQuery({
    queryKey: ["tracks", departureUrl],
    queryFn: async () => {
      const data = await getData(departureUrl);
      return data;
    },
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    placeholderData: {
      type: "FeatureCollection",
      features: [],
    },
    onSuccess: (data) => {
      setDeparturePaths(data);
    },
    onError: (error) => {
      console.error(error);
    },
  });

  if (isError) {
    console.error(error);
    return <></>;
  }

  const filteredData = filterNonJetPaths(data, false);

  // Determine the default opactiy based on selected departures
  let baseOpacity =
    selectedArrivalNumbers.length === 0 && selectedDepartureNumbers.length === 0
      ? departureStyle.paint["fill-opacity"]
      : departureStyle.paint["fill-unselected-opacity"];

  return (
    <Source id="departure-path-data" type="geojson" data={filteredData}>
      <Layer
        source="departure-path-data"
        id={layerId}
        beforeId={beforeId}
        type={departureStyle.type}
        paint={{
          "fill-color": departureStyle.paint["fill-color"],
          "fill-opacity": [
            "case",
            [
              "boolean",
              [
                "in",
                ["get", "subtrack_id"],
                ["literal", selectedDepartureNumbers],
              ],
              false,
            ],
            departureStyle.paint["fill-selected-opacity"],
            baseOpacity,
          ],
        }}
      />
    </Source>
  );
}

export default DepartureLayer;
