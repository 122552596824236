import React, { useCallback, useEffect } from "react";
// styling imports
import "../../styles/Filters.css";
import FilterItem, { BUTTONS_ORDER } from "./FilterItem";
import { trackCombinations, trackPattern } from "../../requests/register";
import { Trans } from "react-i18next";

function FlightFilterGroup({
  filterConfigurations,
  filterSelection,
  setFilterSelection,
  activeIndex,
  toggleText,
  setSelectedArrivals,
  setSelectedDepartures,
  setSelectedArrivalVectoring,
  setSelectedDepartureVectoring,
}) {
  const RUNWAY_KEY = "runway";
  const TIME_KEY = "time";
  const configureFilter = useCallback(
    (filterName, filterValue) => {
      const filterSelectionCopy = { ...filterSelection };
      filterSelectionCopy[filterName] = filterValue;
      setFilterSelection(filterSelectionCopy);
      setSelectedArrivals([]);
      setSelectedDepartures([]);
      setSelectedArrivalVectoring(null);
      setSelectedDepartureVectoring(null);
    },
    [
      setSelectedArrivalVectoring,
      setSelectedArrivals,
      setSelectedDepartureVectoring,
      setSelectedDepartures,
      filterSelection,
      setFilterSelection,
    ]
  );

  useEffect(() => {
    const combinationKeys = Object.keys(trackCombinations);
    const currentCombination = `${filterSelection.time}_${filterSelection.runway}`;

    if (!combinationKeys.includes(currentCombination)) {
      for (let i = 0; i < combinationKeys.length; i++) {
        const combinationParts = combinationKeys[i].split("_");
        if (combinationParts[0] === filterSelection.time) {
          const runway = combinationParts[1];
          configureFilter(RUNWAY_KEY, runway);
        }
      }
    }
  }, [configureFilter, filterSelection]);

  return (
    <>
      <p className="info-persistent">
        <Trans
          i18nKey="filters.flightpaths.info.persistent"
          // eslint-disable-next-line
          components={{ hyperlink: <a />, italic: <i />, bold: <strong /> }}
        />
      </p>
      <FilterItem
        filterKey={RUNWAY_KEY}
        configuration={filterConfigurations.runway}
        selectValue={(v) => configureFilter(RUNWAY_KEY, v)}
        filterSelection={filterSelection}
        filterCombinations={trackCombinations}
        filterCombinationsPattern={trackPattern}
        enabled={true}
        isActive={activeIndex === 1}
        toggleText={() => toggleText(1)}
      />
      <FilterItem
        filterKey={TIME_KEY}
        configuration={filterConfigurations.time}
        selectValue={(v) => configureFilter(TIME_KEY, v)}
        filterSelection={filterSelection}
        filterCombinations={trackCombinations}
        filterCombinationsPattern={trackPattern}
        enabled={true}
        isActive={activeIndex === 2}
        toggleText={() => toggleText(2)}
        buttonsPerRow={2}
        buttonsOrder={BUTTONS_ORDER.ASCENDING}
        forceEnabled={true}
      />
    </>
  );
}

export default FlightFilterGroup;
