import React from "react";
import i18next from "../../../i18n";
import closingIcon from "../../../images/Map/closing-icon.svg";
import arrivalIcon from "../../../images/icons/arrival.png";
import departureIcon from "../../../images/icons/departure.png";
import playIcon from "../../../images/popups/playIcon.svg";
import "../../../styles/popups/TracksPopup.css";
import { Trans } from "react-i18next";

const PopupTitle = ({ handleClose, title }) => {
  return (
    <span className="d-flex justify-content-between">
      <h6 className="popup-title">{title}</h6>
      <img
        src={closingIcon}
        alt="closing icon"
        className="address-loc-marker"
        onClick={handleClose}
      />
    </span>
  );
};

export const ViewButton = ({ flight, findVideoUrl, setVideoOpen }) => {
  const { video_url, reference_name, TRACK_ID } = flight;
  const hasVideo = video_url.startsWith("src=");

  if (!hasVideo) {
    return (
      <button key={TRACK_ID} className="popup-flight-btn" disabled>
        <div className="w-100">{reference_name}</div>
      </button>
    );
  }
  return (
    <button
      key={TRACK_ID}
      className="popup-flight-btn"
      onClick={() => {
        setVideoOpen(true);
        findVideoUrl(TRACK_ID);
      }}
    >
      <img
        src={playIcon}
        alt="video play icon. Basically a triangle that has been rotated to right by 90 degrees."
        className="popup-play-icon"
      />
      <div className="w-100">{reference_name}</div>
    </button>
  );
};

function WsiTracksPopup({
  handleClosePopupTracks,
  selectedArrivals,
  selectedDepartures,
  setVideoOpen,
  findVideoUrl,
}) {
  if (selectedArrivals === undefined && selectedDepartures === undefined)
    return null;

  const joinedPaths = [
    ...Object.values(selectedArrivals),
    ...Object.values(selectedDepartures),
  ];
  const updatedPaths = joinedPaths.filter((path) => path?.updated);

  const anyFlightHasVideo = joinedPaths.some((path) =>
    path?.video_url.startsWith("src=")
  );

  return (
    <div className="popup-wrapper">
      <section className="d-flex justify-content-between">
        <span className="d-flex flex-column justify-content-center w-100">
          {selectedArrivals !== undefined &&
            Object.keys(selectedArrivals).length > 0 && (
              <PopupTitle
                title={i18next.t("Arrivals")}
                handleClose={handleClosePopupTracks}
              />
            )}
          <div className="d-flex align-items-center">
            {selectedArrivals !== undefined &&
              Object.keys(selectedArrivals).length > 0 && (
                <img
                  src={arrivalIcon}
                  alt="an icon of a flight coming down."
                  className="pop-up-flight-icon"
                />
              )}
            <div className="track-popup-btn-wrapper">
              {selectedArrivals !== undefined &&
                Object.values(selectedArrivals).map((value) => (
                  <ViewButton
                    key={value.TRACK_ID}
                    flight={value}
                    findVideoUrl={findVideoUrl}
                    setVideoOpen={setVideoOpen}
                  />
                ))}
            </div>
          </div>
          {selectedDepartures !== undefined &&
            Object.keys(selectedDepartures).length > 0 && (
              <span className="d-flex justify-content-between">
                <h6 className="popup-title">{i18next.t("Departures")}</h6>
                {(selectedArrivals === undefined ||
                  Object.keys(selectedArrivals).length < 1) && (
                  <img
                    src={closingIcon}
                    alt="closing icon"
                    className="address-loc-marker"
                    onClick={() => {
                      handleClosePopupTracks();
                    }}
                  />
                )}
              </span>
            )}
          <div className="d-flex align-items-center">
            {selectedDepartures !== undefined &&
              Object.keys(selectedDepartures).length > 0 && (
                <img
                  src={departureIcon}
                  alt="an icon of a flight taking off."
                  className="pop-up-flight-icon"
                />
              )}
            <div className="track-popup-btn-wrapper">
              {selectedDepartures !== undefined &&
                Object.values(selectedDepartures).map((value) => (
                  <ViewButton
                    flight={value}
                    findVideoUrl={findVideoUrl}
                    setVideoOpen={setVideoOpen}
                  />
                ))}
            </div>
          </div>
        </span>
      </section>
      <div className="w-100 d-flex justify-content-center">
        {(selectedArrivals !== undefined || selectedDepartures !== undefined) &&
          anyFlightHasVideo && (
            <span className="popup-text">
              {i18next.t("popup.flightpaths.underMulti.footer")}
            </span>
          )}
      </div>
      <div className="w-100 d-flex justify-content-center">
        <span className="popup-text">
          {i18next.t("popup.flightpaths.altitude.description")}
        </span>
      </div>
      {updatedPaths.map(({ reference_name, update_translation_id }) => (
        <div className="popup-icon-text-wrapper special-blue">
          <p className="popup-paragraph">
            <b>{reference_name}</b>
            <br />
            <Trans i18nKey={update_translation_id} />
          </p>
        </div>
      ))}
    </div>
  );
}

export default WsiTracksPopup;
