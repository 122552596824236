import { useQuery } from "@tanstack/react-query";
import getData from "../../../requests/getData";

const useArrivalArrowsQuery = (arrivalArrowUrl) =>
  useQuery({
    queryKey: ["arrivalArrows", arrivalArrowUrl],
    queryFn: async () => {
      const data = await getData(arrivalArrowUrl);
      return data;
    },
    enabled: !!arrivalArrowUrl,
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    placeholderData: {
      type: "FeatureCollection",
      features: [],
    },
    onError: (error) => {
      console.error(error);
    },
  });
const useDepartureArrowsQuery = (departureArrowUrl) =>
  useQuery({
    queryKey: ["departureArrows", departureArrowUrl],
    queryFn: async () => {
      const data = await getData(departureArrowUrl);
      return data;
    },
    enabled: !!departureArrowUrl,
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    placeholderData: {
      type: "FeatureCollection",
      features: [],
    },
    onError: (error) => {
      console.error(error);
    },
  });

export { useArrivalArrowsQuery, useDepartureArrowsQuery };
